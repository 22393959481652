import { SeoTags } from 'components/seoTags'
import { Subtitle, Title, TitleDescription } from 'components/text'
import { BgVideo, Route } from 'components/ui'
import { THEME, useTheme } from 'state'

export default function Custom404() {
    const theme = useTheme()

    return (
        <Route>
            <SeoTags
                title='Page Not Found'
                canonicalPath='/404'
                noIndex={true}
            />
            <div className='flex flex-col justify-center items-center h-full py-40 relative text-contrast-3'>
                <BgVideo
                    title='Page Not Found'
                    vimeoId={theme === THEME.DARK ? '831202687' : '831203197'}
                    className='mt-12'
                />
                <Subtitle className='text-center'>Page Not Found</Subtitle>
                <Title className='text-[clamp(70px,20vw,277px)]'>404</Title>
                <TitleDescription
                    large
                    className='text-center max-w-[clamp(300px,60%,600px)]'
                >
                    Sorry, the page you are looking for doesn’t exist or has
                    been moved. Try searching our site:
                </TitleDescription>
            </div>
        </Route>
    )
}
